import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



import logo from '../assets/svg/footerlogo.svg';
import facebook_icon from '../assets/svg/facebook-icon.svg'
import instagram_icon from '../assets/svg/instagram-icon.svg'


import location_icon from '../assets/svg/location-ico.svg';
import phone_icon from '../assets/svg/phone-ico.svg';
import fax_icon from '../assets/svg/fax-ico.svg';
import mail_icon from '../assets/svg/mail-ico.svg';


export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        if (process.env.REACT_APP_IS_APP){
            return null;
        }
        
        return (
            <footer className="footer">
                <Container>

                    <Row>
                        <Col lg="9">
                            <ul className="navigation">
                                <li><Link to='/'>Početna</Link></li>
                                <li><Link to='/o-nama'>O nama</Link></li>

                                <li><Link to='/usluge'>Usluge čišćenja</Link></li>

                                <li><Link to='/category'>Proizvodi</Link></li>

                                <li><Link to='/galerija'>Galerija</Link></li>

                                <li><Link to='/blog'>Novosti</Link></li>
                                <li><Link to='/katalog'>Katalog</Link></li>
                                <li><Link to='/contact'>Kontakt</Link></li>
                                <li><Link to='/page/upusto-za-koristenje'>Upusto za korištenje</Link></li>

                                

                            </ul>
                        </Col>
                        <Col lg="3" className="social">
                            <span>Pratite nas</span>
                            <a href='https://www.facebook.com/MasterCleanBiH' target="_blank"><Isvg src={facebook_icon} /></a>
                            <a href='https://www.instagram.com/master_clean_bih/' target="_blank"><Isvg src={instagram_icon} /></a>

                        </Col>

                        <div className="spacer1"></div>

                        <Col md="4">
                            <div className="logo">
                                <Isvg src={logo} />
                            </div>
                            <p>Nudimo Vam sve vrste profesionalnog redovnog ili jednokratnog čišćenjenja u cijeloj BiH. Ovlašteni smo uvoznik i distributer sredstava i opreme za ličnu i prostornu higijenu. </p>
                        </Col>
                        <Col md="3">
                            <div>
                                <h6>INFORMACIJE O FIRMI</h6>
                                <p>Veljka Milankovića 1<br />
                                78430 Prnjavor<br /><br />
                                
                                    TEL/FAX:  +387 51 663 132<br />
                                    EMAIL: info@masterclean.ba</p>
                            </div>

                        </Col>
                        <Col md="3" xs="6">
                            <div>
                                <h6>RADNO VRIJEME</h6>
                                <p>Pon - Pet: 7:30 - 15:30<br />
                                    Subota: 7:30 - 12:30<br />
                                    </p>

                            </div>

                        </Col>
                        <Col md="2" xs="6">
                            <div >
                                <h6>POZOVITE NAS</h6>
                                <a className="telephone" href="tel:// +387 65 400 805"> +387 65 400 805</a><br/>
                                <a className="telephone" href="tel://+387 66 177 300">+387 66 177 300</a>
                                <p>Uvijek dostupni za Vas!</p>
                            </div>

                        </Col>


                        <div className="spacer"></div>

                        <div className="copyright">
                            <p>Copyright © MasterClean d.o.o. - 2019. All Rights Reserved.</p>
                            <p>Created by <span><a href="https://www.novamedia.agency">NOVA media</a></span></p>
                        </div>

                    </Row>
                </Container>

            </footer>
        )
    }
}

export default Footer;