import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import Newsletter from '../components/newsletter';
import Map from '../components/map';
import Footer from '../containers/footer';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class CatalogPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            pdf: []

        };

    }



    componentDidMount() {
        window.scrollTo(0,0);
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchPdf', (data) => {
            this.setState({
                pdf: data
            })
        });


        this.props.socketIOClient.emit("fetchPdf", {});


    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;

    }



    render() {

        console.log(this.state)

        return (
            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Media</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Media</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section about-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h3>Katalozi</h3>
                            </Col>
                                {
                                    this.state.pdf && this.state.pdf.map((item, idx) => {
                                        return (
                                            item.pdfFile != null ?
                                            <Col lg="3">
                                                <div className="pdf-item">
                                                    <a href={item.pdfFile} target= "_blank">
                                                        <img src={item.pdfImage} />
                                                        <h6>{item.pdfTitle}</h6>
                                                    </a>
                                                </div>
                                            </Col> 
                                            :
                                            null
                                        )
                                    })
                                }
                             <Col lg="12" style={{ marginTop: 40 }}>
                                <h3>Video</h3>
                            </Col>

                                {
                                    this.state.pdf && this.state.pdf.map((item, idx) => {
                                        return (
                                            item.video == null ?
                                            
                                            null

                                            :

                                            <Col lg="4">
                                                <div className="video-item">
                                                    <a href={item.videoAlias} target= "_blank">
                                                        <img src={item.video} />
                                                        <h6>{item.videoTitle}</h6>
                                                    </a>
                                                </div>
                                            </Col> 
                                        )
                                    })
                                }
                        </Row>
                    </Container>
                </section>

                <Newsletter {...this.props} />

                <Map {...this.props} />
                <Footer {...this.props} />

            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CatalogPage));
