import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleMobileSearchForm, handleMenu } from '../../actions/index';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import { changeLanguage } from '../../actions';
import Categories from '../../components/categories';
import mark from '../../assets/svg/mark.svg'

import facebook_icon from '../../assets/svg/facebook-icon.svg'
import instagram_icon from '../../assets/svg/instagram-icon.svg'
import credit_card_icon from '../../assets/svg/credit-card-icon.svg'
import heart_icon from '../../assets/svg/heart-icon.svg'
import account_icon from '../../assets/svg/account-icon.svg'
import wishlist_icon from '../../assets/svg/wishlist.svg'
import categories_icon from '../../assets/svg/categories.svg'

import cart_icon from '../../assets/svg/cart-icon.svg'
import phone_icon from '../../assets/svg/phone-icon.svg'
import hamburger_icon from '../../assets/svg/hamburger-icon.svg'
import home_icon from '../../assets/svg/home-icon.svg'
import phone1_icon from '../../assets/svg/phone.svg'
import category_icon from '../../assets/svg/category-icon.svg'
import searchIcon from '../../assets/svg/search.svg'
import catalog from '../../assets/svg/catalog.svg'

import logo from '../../assets/svg/logo.svg';
import image from '../../assets/images/no-image.jpg';


import drawerAccount from '../../assets/svg/drawer/account.svg';
import drawerCart from '../../assets/svg/drawer/cart.svg';
import drawerHome from '../../assets/svg/drawer/home.svg';
import drawerLogout from '../../assets/svg/drawer/logout.svg';
import drawerPhone from '../../assets/svg/drawer/phone.svg';
import drawerWishlist from '../../assets/svg/drawer/wishlist.svg';
import drawerQuestion from '../../assets/svg/question.svg';
import close_ico from '../../assets/svg/close-ico.svg';
import headerPhoneIco from '../../assets/svg/header-phone.svg';
import headerMobileIco from '../../assets/svg/header-mobile.svg';
import langIco from '../../assets/svg/sr.png';
import account_ico from '../../assets/svg/header-account.svg';


class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {
            //console.log(data);

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }
    }


    render() {
        return (
            <header className={process.env.REACT_APP_IS_APP ? 'app-header': null}>
                <div className="header-top">
                    <Container>
                        <Row>
                            <Col lg="12" className="header-top-container">
                                <div className="info">
                                    <p> Za sve informacije nas možete kontaktirati na telefon: <a href="tel://+387 65 400 805">+387 65 400 805</a></p>
                                </div>

                                <div className="social">
                                    <span>Pratite nas</span>
                                    <a href='https://www.facebook.com/MasterCleanBiH' target="_blank"><Isvg src={facebook_icon} /></a>
                                    <a href='https://www.instagram.com/master_clean_bih/' target="_blank"><Isvg src={instagram_icon} /></a>
                                </div>

                            </Col>


                        </Row>
                    </Container>
                </div>

                <Container className="header">
                    <Row>
                        <Col lg={{ size: 2, order: 0 }} xs={{ size: 2, order: 0 }} className='hamburger d-lg-none d-xl-none' onClick={() => { this.setState({ leftMenu: !this.state.leftMenu }) }}>
                            <Isvg src={hamburger_icon} />
                        </Col>

                        <Col xl={{ size: 3, order: 0 }} xs={{ size: 5, order: 1 }} sm={{ size: 5, order: 1 }} md={{ size: 8, order: 1 }} className="logo">
                            <Link to='/'><Isvg src={logo} /></Link>
                        </Col>

                        <Col className='d-lg-none d-xl-none d-md-block' xs={{ size: 5, order: 4 }} md={{ size: 2, order: 4 }} className="mobile-actions">
                            <button className="search-icon" onClick={() => this.setState({ showSearch: true })}><Isvg src={searchIcon} /></button>
                            <Link to='/cart'><Isvg src={cart_icon} />{this.props.cartCount ? <div className="cart-count">{this.props.cartCount}</div> : null}</Link>

                            <button onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}>
                                <Isvg src={category_icon} />
                            </button>
                        </Col>


                        <Col xl={{ size: 9 }} className="d-none d-sm-none d-md-none d-xl-block">
                            <div className="navigation-container">
                                <ul className="navigation">

                                    <li><Link className={this.props[0].location.pathname == '/' ? 'active' : null} to='/'>Početna</Link></li>
                                    <li><Link className={this.props[0].location.pathname == '/o-nama' ? 'active' : null} to='/o-nama'>O nama</Link></li>
                                    <li><Link className={this.props[0].location.pathname.indexOf('usluge') != -1 ? 'active' : null} to='/usluge'>Usluge čišćenja</Link></li>
                                    <li><Link className={this.props[0].location.pathname.indexOf('brendovi') != -1 ? 'active' : null} to='/brendovi'>Proizvodi</Link></li>
                                   
                                    <li><Link className={this.props[0].location.pathname.indexOf('galerija') != -1 ? 'active' : null} to='/galerija'>Galerija</Link></li>
                                    <li><Link className={this.props[0].location.pathname == '/blog' ? 'active' : null} to='/blog'>Novosti</Link></li>
                                    <li><Link className={this.props[0].location.pathname == '/katalog' ? 'active' : null} to='/katalog'>Katalog</Link></li>
                                    <li><Link className={this.props[0].location.pathname.indexOf('/category') != -1 ? 'active' : null} to='/category'>WEB SHOP</Link></li>
                                    <li><Link className={this.props[0].location.pathname.indexOf('contact') != -1 ? 'active' : null} to='/contact'>Kontakt</Link></li>


                                </ul>
                            </div>

                        </Col>


                    </Row>
                </Container>
                {!process.env.REACT_APP_IS_APP && (
                this.props[0].location.pathname.indexOf('/category') !== -1 ||
                 this.props[0].location.pathname.indexOf('/product') !== -1 ||
                  this.props[0].location.pathname.indexOf('/account') !== -1 ||
                  this.props[0].location.pathname.indexOf('/login') !== -1 ||
                  this.props[0].location.pathname.indexOf('/register') !== -1 ||
                  this.props[0].location.pathname.indexOf('/forget-password') !== -1 ||

                   this.props[0].location.pathname.indexOf('/cart') !== -1) ?
                    <div className="header shop-header">
                        <Container>
                            <Row>
                                <Col lg="3">
                                    <h1>Prizvodi</h1>
                                </Col>
                                <Col lg={{ size: 5, order: 0 }} xs={{ size: 6, order: 2 }} className="search-form d-none d-sm-none d-md-none d-xl-block">
                                    <input onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            this.props[0].history.push(`/category/&search=${this.state.search}`)
                                        }
                                    }} onFocus={this.suggestions} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }, () => this.suggestions()) }} type="text" placeholder={"Pretraga..."} />

                                    <button onClick={() => {
                                        this.props[0].history.push(`/category/&search=${this.state.search}`)
                                    }}><Isvg src={searchIcon} /></button>
                                    {this.state.suggestions.length ?
                                        <div className="search-results" ref={(node) => { this.wrapperRef = node; }}>
                                            <h6>Artikli</h6>
                                            <ul>
                                                {
                                                    this.state.suggestions.map((item, idx) => {
                                                        return (
                                                            <li key={idx}><Link to={`/product/${item.Alias}/${item._id}`}><img onError={() => {
                                                                let imageErrors = this.state.imageErrors;
                                                                imageErrors[item.Image] = true;
                                                                this.setState({ imageErrors })
                                                            }} src={!this.state.imageErrors[item.Images && item.Images.length && item.Images[0]] ? (item.Images && item.Images.length && item.Images[0]) ? item.Images[0].scaleImage(200) : image : image} />{item.Name}</Link></li>

                                                        )
                                                    })
                                                }

                                            </ul>
                                        </div>
                                        : null}
                                </Col>

                                <Col lg={{ size: 4, order: 0 }} xs={{ size: 1, order: 0 }} className="account d-none d-sm-none d-md-none d-xl-block">
                                    {this.props.uData ?
                                        <Link to='/account' className="wishlist-button">
                                            <div>
                                                <Isvg src={account_ico} />
                                                Nalog
                                        </div>
                                        </Link>

                                        :
                                        <Link to='/login' className="wishlist-button">
                                            <div>
                                                <Isvg src={account_ico} />
                                                Login
                                    </div>
                                        </Link>
                                    }

                                    <Link to='/account/wishlist' className="wishlist-button">
                                        <Isvg src={wishlist_icon} />
                                    </Link>


                                    <Link to='/cart' className="cart-button">
                                        <Isvg src={cart_icon} />
                                        <div className="cart-text">
                                            <div className="count">{this.props.cartCount}</div>
                                            <p>Korpa</p>
                                            <p>{this.props.cartTotal.formatPrice(this.props.currency)}</p>
                                        </div>
                                    </Link>

                                </Col>



                            </Row>

                        </Container>
                    </div>

                    :
                    null

                }

                {this.state.showSearch ?
                    <div className="search-container">
                        <button className="close" onClick={() => this.setState({ showSearch: false })}>
                            <Isvg src={close_ico} />
                        </button>
                        <div className="input">
                            <input onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                }
                            }} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }) }} type="text" placeholder={"Pretražite..."} />
                            <button onClick={() => {
                                this.setState({
                                    showSearch: false
                                }, () => {
                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                })
                            }}><Isvg src={searchIcon} /></button>
                        </div>
                    </div>
                    : null
                }

                <div className={this.state.leftMenu ? 'mobile-left-menu mobile-left-menu-open' : 'mobile-left-menu'}>
                    <div className="content" ref={(node) => this.leftMenuRef = node}>
                        <div className="user-area">
                            <div className="user-image">
                                <Isvg src={account_icon} />
                            </div>

                            {this.props.uData && <p>{this.props.uData.User.Name}</p>}
                            {this.props.uData && <p>{this.props.uData.User.EMail}</p>}
                            {!this.props.uData && <p><Link to='/login'>{this.props.translate('Prijava')}</Link> / <Link to='/register'>{this.props.translate('Registracija')}</Link></p>}


                        </div>

                        <ul>
                            <li> <Link to='/'><Isvg src={drawerHome} />Početna </Link> </li>

                            {this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} /> Nalog </Link> </li>}
                            <li> <Link to='/cart'><Isvg src={drawerCart} />Korpa </Link> </li>
                            {this.props.uData && <li> <Link to='/account/wishlist'><Isvg src={drawerWishlist} />Lista omiljenih </Link> </li>}

                           {!process.env.REACT_APP_IS_APP ? <li> <Link to='/contact'><Isvg src={drawerPhone} />Kontakt </Link> </li> : null }

                            {this.props.uData && <li> <a onClick={() => { this.props.setUserData(null); this.props.socketIOClient.emit('userLogout', {}); }}><Isvg src={drawerLogout} />Odjava </a> </li>}
                           <li> <a target="_blank" href="/katalog.pdf"><Isvg src={catalog} />Katalog </a> </li>

                            {!this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} />Prijava </Link> </li>}

                        </ul>
                    </div>
                </div>

                <div className={this.state.rightMenu ? 'mobile-right-menu mobile-right-menu-open' : 'mobile-right-menu'}>
                    <div className="content" ref={(node) => this.rightMenuRef = node}>
                        <h3>KATEGORIJE</h3>

                        <button className="close" onClick={() => this.setState({ rightMenu: false })}>
                            <Isvg src={close_ico} />
                        </button>


                        <Categories {...this.props} selectCategory={(cat, level) => {
                            let state = { page: 0 };
                            state['_selectedCategory' + level] = cat._id;

                            this.setState(state);

                        }}

                            _selectedCategory0={this.state._selectedCategory0}
                            _selectedCategory1={this.state._selectedCategory1}
                            _selectedCategory2={this.state._selectedCategory2}
                            _selectedCategory3={this.state._selectedCategory3}
                            _selectedCategory4={this.state._selectedCategory4}


                        />


                    </div>
                </div>


            </header>

        );
    }
}

const mapStateToProps = state => ({
    searchForm: state.searchForm,
    menu: state.menu
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChange: (val) => {
            dispatch(handleMobileSearchForm(val))
        },
        handleMenu: (val) => {
            dispatch(handleMenu(val))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
