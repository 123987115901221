

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';
import popup_bg from './assets/images/popupbg.png';
import coupon from './assets/images/coupon.png';
import logo from './assets/images/footer-logo.png';
import close_ico from './assets/svg/close-ico.svg';
import Isvg from 'react-inlinesvg';
import ReactGA from 'react-ga';
import MessengerCustomerChat from 'react-messenger-customer-chat';


Number.prototype.formatPrice = function (currency) {
  let price = this;
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}

String.prototype.formatPrice = function (currency) {
  let price = parseFloat(this);
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}


String.prototype.scaleImage = function (mobileWidth=200) {
  return this;
  if (window._webpSupport){
    ////console.log(this.replace('.jpg','').replace('.jpeg','').replace('.png','').replace('.gif','')+'.webp');
    if (window.innerWidth <= 500){
      return this.replace('/uploads', `/uploads-${mobileWidth}x`).replace('.jpg','').replace('.jpeg','').replace('.png','').replace('.gif','')+'.webp';
    }else{
      return this.replace('.jpg','').replace('.jpeg','').replace('.png','').replace('.gif','')+'.webp';
    }
  }

  if (window.innerWidth <= 500){
    return this.replace('/uploads', `/uploads-${mobileWidth}x`);
  }else{
    return this;
  }
}



class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    window.googleMapsCallback = this.googleMapsCallback;
    this.setCurrency = this.setCurrency.bind(this);


    this.state = {
      lang: 'sr',
      breadcrumb: [],
      categories: [],
      uData: null,
      cartCount: 0,
      newestProducts: [],
      popularProducts: [],
      offerProducts: [],
      promotedProducts: [],
      news: [],
      infoMessages: {

      },
      partners: [],
      companies: [],
      currency: {
        code: 'KM',
        exchangeRate: 1
      },
      cartTotal: 0,
      pages: {
        'nacini-placanja': {},
        'brza-i-pouzdana-dostava': {},
        'cesta-pitanja': {},
        'uslovi-koristenja': {},
        'kako-naruciti': {},
        'garancija': {},



      }
    }
  }


  setCurrency(currency){
    this.setState({
      currency
    })
  }

  showInfoMessage(text, error) {
    //console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  hidePopup() {
    localStorage.popup = Math.floor(Date.now() / 1000);
    //console.log(true)
    this.setState({
      popup: null
    })
  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  componentDidMount() {

    console.log(process.env);

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    let socket = socketIO('https://socket.masterclean.ba');

    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        socket.emit("userVerify", { email: uData.User.EMail, pk: uData.User.pk });
    }


    socket.on('cartInfo', (data) => {
      this.setState({
        cartCount: data.count,
        cartTotal: data.total
      })
    })

    socket.on('userVerify', (data) => {
      //console.log(data);
      if (data.successful) {
        this.setUserData(data.user);
      }

      socket.emit('cartInfo', {});

    });
    

    


    socket.on('fetchSpecialOfferProducts', (data) => {
      this.setState({
        promotedProducts: data
      })
    });
        socket.on('fetchNewestProducts', (data) => {
      this.setState({
        newestProducts: data
      })
    });
    



    socket.on('fetchCategories', (data) => {
      this.setState({
        categories: data
      });
    });

    socket.on('fetchNews', (data) => {
      //console.log(data);
      this.setState({
        news: data
      });
    });

    socket.on('fetchPartners', (data) => {
      //console.log(data);
      this.setState({
        partners: data
      });
    });
    socket.on('fetchCompanies', (data) => {
      //console.log(data);
      this.setState({
        companies: data
      });
    });
    socket.on('fetchPdf', (data) => {
      //console.log(data);
      this.setState({
        pdf: data
      });
    });


    socket.on('fetchPopup', (data) => {
      //console.log(data);
      this.setState({
        popupData: data
      });
    });
    socket.on('fetchPages', (data) => {
      //console.log(data);
      this.setState({
        pages: data
      });
    });



    socket.on('userLogout', (data) => {
      socket.emit('cartInfo', {});
    })
    socket.emit('fetchPartners', {});
    socket.emit('fetchCompanies', {});
    socket.emit('fetchPdf', {});

    socket.emit('fetchCategories', {});
    socket.emit('fetchSpecialOfferProducts', {});
    socket.emit('fetchNewestProducts', {});

    socket.emit('fetchNews', {});
    socket.emit('fetchPages', {});

    socket.on('siteData', (data) => {
      //console.log(data);
      this.setState({
        siteData: data
      });
    });



  }


  translate(text) {
    return text;
  }

  render() {
    //console.log(window._webpSupport);
    console.log(this.state)
    return (
      <div>
        <Routes
          translate={this.translate}
          updateBreadcrumb={this.updateBreadcrumb}
          setUserData={this.setUserData}
          allowCookies={this.allowCookies}
          showInfoMessage={this.showInfoMessage}
          hideInfoMessage={this.hideInfoMessage}
          hidePopup={this.hidePopup}
          setCurrency={this.setCurrency}
          changeLang={(lang) => {
            this.setState({
              lang: lang,
              currency: lang == 'sr' ? {
                code: 'KM',
                exchangeRate: 1
              } :
              {
                code: 'EUR',
                exchangeRate: 0.512
              }
        
            })
          }}

          {...this.state}
        />
        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              //console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <Isvg src={close_ico} className="hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ?  <Isvg src={close_ico} /> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

            
      

      </div>

    );

  }

}

export default App;
