import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import BlogArticle from '../components/blogArticle';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';
import yellow_stars from '../assets/svg/yellow-stars.svg';

import Map from '../components/map';

import pdf from '../assets/katalog.pdf';
// import Pdf from '../components/pdf/Pdf';
import { Document, Page as Pageee, pdfjs } from 'react-pdf';

import {
    Container,
    Row,
    Col,
    Dropdown,
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class BrandsPage extends Component {
    constructor(props) {
        super(props);
        if(typeof window != 'undefined')
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        this.state = {
            selectedProduct: null,
            pageNumber: null,
            startingPageNumber: null,
            endPageNumber: null,
            // numPages: 1
        };

    }

    componentDidMount(){
        window.scrollTo(0,0);
    }
    onDocumentLoadSuccess = ({ numPages }) => {

        // if (this.state.numPages != numPages) {
        //     this.setState({
        //         numPages: numPages
        //     })
        // }
        this.setState({numPages: this.state.endPageNumber})


    }

    nextPage = () => {
        if (this.state.pageNumber < this.state.numPages)
            this.setState({
                pageNumber: this.state.pageNumber + 1
            })
    }
    backPage = () => {
        if (this.state.pageNumber != 1)
            this.setState({
                pageNumber: this.state.pageNumber - 1
            })
    }
    render() {
        console.log(this.state.pageNumber);
        console.log(this.state.selectedProduct);
        // console.log(this.state.selectedProduct);
        return (
            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Brendovi</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Brendovi</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-brands">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>PROIZVODI IZ NAŠEG PRODAJNOG ASORTIMANA</h2>
                                <p>Kao što se i očekuje od pouzdanog poslovnog partnera, u našem asortimanu nalaze se odabrani vrhunski brendovi iz svijeta higijene, a sve kao rezultat višegodišnje svakodnevne posvećenosti našeg tima za istraživanjem tržišta, ispitivanjem noviteta na tržištu i prepoznavanju potreba naših poslovnih partnera .
                                Za sve navedene proizvode i brendove Master clean je ovlašteni uvoznik i distributer za BiH.
                                </p>
                            </Col>

                            {
                                this.props.partners.map((item, idx) => {
                                    return (
                                        <Col lg="3" xs="12" sm="6">
                                            <div className="brand" onClick={() => this.setState({selectedProduct: item, startingPageNumber: Number(item.pageNumber), endPageNumber: Number(item.endPageNumber ? item.endPageNumber : item.pageNumber), pageNumber: Number(item.pageNumber)})}>
                                                <a>
                                                    <img src={item.Image} />
                                                    <p>{item.Name}</p>
                                                </a>
                                            </div>
                                            
                                        </Col>

                                    )

                                })
                            }

                            {this.state.selectedProduct && <Modal size='lg' toggle={() => this.setState({selectedProduct: null})} isOpen={this.state.selectedProduct}>
                                <ModalHeader toggle={() => this.setState({selectedProduct: null})}></ModalHeader>
                                <ModalBody>
                                {/*<Pdf pdf={pdf} pageNumber={1}/>*/}

                                <Document style={{margin: '0 auto'}}
                                                    file={pdf}
                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                    error={"Failed to load PDF file."}
                                                    loading={"Loading PDF..."}
                                                    noData={"No page specified."}
                                                >
                                                    <Pageee width={this.pdfDocument ? this.pdfDocument.clientWidth : null} pageNumber={this.state.pageNumber} />
                                                </Document>
                                                <div className="page-pagination">
                                                     <Button disabled={this.state.pageNumber === this.state.startingPageNumber} onClick={this.backPage}>{'Previous'}</Button>
                                                    
                                                    <Button disabled={this.state.pageNumber === this.state.endPageNumber} onClick={this.nextPage}>{'Next'}</Button>
                                                </div>
                                </ModalBody>
                            </Modal>}



                        </Row>
                    </Container>
                </section>

                <Newsletter {...this.props} />
                <Map {...this.props}></Map>

                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(BrandsPage));
