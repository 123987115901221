import React, { Component } from 'react';
import {
    //HashRouter as Router,
    Router,
    Route,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { handleMobileSearchForm, handleMenu } from './actions/index';

import { createBrowserHistory } from 'history';

import asyncComponent from './AsyncComponent'
import { GoogleMapScript } from './components/googleMapScript';

/*
import HomePage from './views/homePage';
import CategoryPage from './views/categoryPage';
import DetailPage from './views/detailPage';
import LoginPage from './views/loginPage';
import RegisterPage from './views/registerPage';
import ChangePasswordPage from './views/changePasswordPage';
import NewsletterPage from './views/newsletterPage';

import AccountPage from './views/accountPage';
import CartPage from './views/cartPage';
import AccountDataPage from './views/accountDataPage';
import AccountAddressPage from './views/accountAddressPage';
import WishlistPage from './views/wishlistPage';
import OrdersPage from './views/ordersPage';
import PrivacyPolicyPage from './views/privacyPolicyPage';

import ForgetPasswordPage from './views/forgetPasswordPage';
import ResetPasswordPage from './views/resetPasswordPage';
import ContactPage from './views/contactPage';
import ConfirmMailPage from './views/confirmMailPage';
import ErrorPage from './views/errorPage';
import ArticlesPage from './views/articlesPage';*/

import HomePage from './views/homePage';
import AppHomePage from './views/appHomePage';

import AboutUsPage from './views/aboutUsPage';
import ServicePage from './views/servicePage';
import GalleryPage from './views/galleryPage';


import CategoryPage from './views/categoryPage';
import DetailPage from './views/detailPage';
import AppDetailPage from './views/appDetailPage';

import LoginPage from './views/loginPage';
import RegisterPage from './views/registerPage';
import ChangePasswordPage from './views/changePasswordPage';
import NewsletterPage from './views/newsletterPage';
import CatalogPage from './views/catalogPage';

import AccountPage from './views/accountPage';
import CartPage from './views/cartPage';
import AccountDataPage from './views/accountDataPage';
import AccountAddressPage from './views/accountAddressPage';
import WishlistPage from './views/wishlistPage';
import OrdersPage from './views/ordersPage';
import PrivacyPolicyPage from './views/privacyPolicyPage';
import Blog from './views/newsPage';
import BlogDetail from './views/newsDetailPage';


import ForgetPasswordPage from './views/forgetPasswordPage';
import ResetPasswordPage from './views/resetPasswordPage';
import ContactPage from './views/contactPage';
import ConfirmMailPage from './views/confirmMailPage';
import ErrorPage from './views/errorPage';
import ArticlesPage from './views/articlesPage';
import BrandsPage from './views/brandsPage';
import CompaniesPage from './views/companiesPage';

import LocationsPage from './views/locationsPage';

const history = createBrowserHistory();

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        const unlisten = history.listen((location, action) => {
            //this.props.handleMenu(null);
            this.props.handleMenu(null);
            this.props.handleChange(null);
            window.scrollTo(0, 0);
        });
    }

    render() {
        return (
            <Router history={history} onUpdate={() => window.scrollTo(0, 0)} >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyDx7uNRz2GYWKLlAlfT6wugFOSBXQ7EZaQ" />

                    <Switch>

                        {process.env.REACT_APP_IS_APP ?
                            <Route
                                path="/"
                                exact
                                render={(...renderProps) => (
                                    <AppHomePage {...renderProps} {...this.props} />
                                )}
                            />

                            :
                            <Route
                                path="/"
                                exact
                                render={(...renderProps) => (
                                    <HomePage {...renderProps} {...this.props} />
                                )}
                            />

                        }
                        <Route
                            path="/o-nama"

                            render={(...renderProps) => (
                                <AboutUsPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/usluge"

                            render={(...renderProps) => (
                                <ServicePage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/galerija"

                            render={(...renderProps) => (
                                <GalleryPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/katalog"

                            render={(...renderProps) => (
                                <CatalogPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/category"

                            render={(...renderProps) => (
                                <CategoryPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/blog"
                            exact
                            render={(...renderProps) => (
                                <Blog {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/blog/:id"
                            exact
                            render={(...renderProps) => (
                                <BlogDetail {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/novo-u-ponudi"

                            render={(...renderProps) => (
                                <ArticlesPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/najpopularnije"

                            render={(...renderProps) => (
                                <ArticlesPage {...renderProps} {...this.props} />
                            )}
                        /> <Route
                            path="/izdvajamo"

                            render={(...renderProps) => (
                                <ArticlesPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/specijalna-ponuda"

                            render={(...renderProps) => (
                                <ArticlesPage {...renderProps} {...this.props} />
                            )}
                        />

                        {process.env.REACT_APP_IS_APP ?

                            <Route
                                path="/product/:alias/:id"
                                exact
                                render={(...renderProps) => (
                                    <AppDetailPage {...renderProps} {...this.props} />
                                )}
                            />
                            :
                            <Route
                                path="/product/:alias/:id"
                                exact
                                render={(...renderProps) => (
                                    <DetailPage {...renderProps} {...this.props} />
                                )}
                            />
                        }

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/account/change-password"
                            exact
                            render={(...renderProps) => (
                                <ChangePasswordPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/register"
                            exact
                            render={(...renderProps) => (
                                <RegisterPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/account/newsletter"
                            exact
                            render={(...renderProps) => (
                                <NewsletterPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account"
                            exact
                            render={(...renderProps) => (
                                <AccountPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/cart"
                            exact
                            render={(...renderProps) => (
                                <CartPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/cart/:paypalReturnInfo"
                            exact
                            render={(...renderProps) => (
                                <CartPage {...renderProps} {...this.props} />
                            )}
                        />




                        <Route
                            path="/account/edit"
                            exact
                            render={(...renderProps) => (
                                <AccountDataPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account/address"
                            exact
                            render={(...renderProps) => (
                                <AccountAddressPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account/wishlist"
                            exact
                            render={(...renderProps) => (
                                <WishlistPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account/orders"
                            exact
                            render={(...renderProps) => (
                                <OrdersPage {...renderProps} {...this.props} />
                            )}
                        />



                        <Route
                            path="/page/:alias"
                            exact
                            render={(...renderProps) => (
                                <PrivacyPolicyPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/forget-password"
                            exact
                            render={(...renderProps) => (
                                <ForgetPasswordPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/reset-password/:uid/:code"
                            exact
                            render={(...renderProps) => (
                                <ResetPasswordPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/contact"
                            exact
                            render={(...renderProps) => (
                                <ContactPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/potvrda/:uid/:code"
                            exact
                            render={(...renderProps) => (
                                <ConfirmMailPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/brendovi"
                            exact
                            render={(...renderProps) => (
                                <BrandsPage {...renderProps} {...this.props} />
                            )}
                        />

                                                <Route
                            path="/reference"
                            exact
                            render={(...renderProps) => (
                                <CompaniesPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/pokrivenost"
                            exact
                            render={(...renderProps) => (
                                <LocationsPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route

                            render={(...renderProps) => (
                                <ErrorPage {...renderProps} {...this.props} />
                            )}
                        />
                    </Switch>
                </div>
            </Router >
        );
    }
}



const mapStateToProps = state => ({
    searchForm: state.searchForm,
    menu: state.menu
});


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChange: (val) => {
            dispatch(handleMobileSearchForm(val))
        },
        handleMenu: (val) => {
            dispatch(handleMenu(val))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Routes);
